import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';

// Import FontAwesome icons
import './icons.js';

import { importGlobalComponents } from './components/globals';
importGlobalComponents();

import { setupFormulate } from './formulate';
setupFormulate();

import { installConstantsPlugin } from './plugins/constants';
installConstantsPlugin(); // Install support for constants inside vue components

import i18n from './i18n';
const VueExtended = Vue.extend({ i18n });

const recaptchaScript = document.createElement('script');
recaptchaScript.setAttribute(
  'src',
  `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`
);
recaptchaScript.setAttribute('async', 'async');
document.head.appendChild(recaptchaScript);

// The code below registers all the web components, creates dummy views for them and returns the router.

export const isRegistered = name => {
  // Ref: https://stackoverflow.com/a/28210364
  return document.createElement(name).constructor !== HTMLElement;
};

export const registerElement = (name, component) => {
  if (!isRegistered(name)) {
    const WrappedComponent = wrap(VueExtended, component);
    window.customElements.define(name, WrappedComponent);
    return true;
  }
  return false;
};

const routeLinks = [];
export const routes = [];

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the web-components directory
  './web-components',
  // Do not look in subdirectories
  false,
  // Only include .vue files
  /[\w-]+\.vue/,
  'lazy'
);

/**
 * Dynamic Routing for Web Components
 *
 * Overview:
 * We've updated our routing system to allow for dynamic passing of an `entityId` to our web components.
 * This change enhances the flexibility and reusability of our components, allowing them to fetch or
 * display data based on the provided entity ID.
 *
 * Changes Made:
 * 1. Updated the route path to include an optional `entityId` parameter:
 *    path: '/' + webComponentName + '/:entityId?'
 *
 * 2. Enabled automatic prop passing in the route configuration:
 *    props: true
 *
 * 3. Modified the component rendering to pass the `entityId` as a prop:
 *    props: { entityId: this.$route.params.entityId }
 *
 * Rationale:
 * - Improved Flexibility: Can use the same component for both creating new entities and editing existing ones.
 * - Enhanced Reusability: Components can be easily reused across different contexts.
 * - Simplified State Management: Eliminates the need for complex state management solutions for basic CRUD operations.
 * - Consistent Behavior: Ensures consistent behavior across different stages of development.
 *
 * Usage:
 * With these changes, you can now use URLs like:
 * /dew-wc-your-component/123
 * The component will receive `123` as the value of the `entityId` prop.
 *
 * Next Steps:
 * 1. Update existing components to handle the `entityId` prop appropriately.
 * 2. Consider implementing logic in components to fetch data based on the `entityId` when present.
 * 3. Ensure error handling for cases where an invalid `entityId` is provided.
 */

// For each matching file name...
requireComponent.keys().forEach(filePath => {
  // Get the PascalCase version of the component name
  const componentName = filePath
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '');

  const webComponentName =
    'dew-wc-' +
    componentName
      // Convert PascalCase to kebab-case
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      // Convert to lower case
      .toLowerCase();

  // Register the web-component
  const webComponent = () => requireComponent(filePath);
  registerElement(webComponentName, webComponent);

  routes.push({
    path: '/' + webComponentName + '/:entityId?',
    name: componentName,
    props: true,
    component:
      process.env.NODE_ENV === 'production'
        ? Vue.component(componentName, {
            // Production: Create a dummy view to nest the web component.
            render: function(createElement) {
              return createElement(webComponentName, {
                props: { entityId: this.$route.params.entityId }
              });
            }
          })
        : {
            render: function(createElement) {
              // Locally: Use the component directly to test it.
              return createElement(webComponent, {
                props: { entityId: this.$route.params.entityId }
              });
            }
          }
  });

  // Push link to the above route to the array routeLinks.
  routeLinks.push({ name: componentName });
});

routes.push({
  path: '/',
  name: 'Home',
  component: Vue.component('Home', {
    data() {
      return {
        searchQuery: ''
      };
    },
    render: function(createElement) {
      // Filter links based on search query
      const filteredLinks = this.searchQuery
        ? routeLinks.filter(link =>
            link.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        : routeLinks;

      // Group filtered links alphabetically
      const groupedLinks = filteredLinks.reduce((acc, link) => {
        const firstLetter = link.name.charAt(0).toUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(link);
        return acc;
      }, {});

      // Sort the groups
      const sortedLetters = Object.keys(groupedLinks).sort();

      return createElement('div', { class: 'my-10 mx-auto max-w-4xl' }, [
        // Search input
        createElement('input', {
          class:
            'w-full p-2 mb-8 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none',
          attrs: {
            type: 'text',
            placeholder: 'Search components...'
          },
          domProps: {
            value: this.searchQuery
          },
          on: {
            input: event => {
              this.searchQuery = event.target.value;
            }
          }
        }),
        // Components groups
        ...sortedLetters.map(letter => {
          return createElement('div', { class: 'mb-12' }, [
            // Letter heading
            createElement(
              'h2',
              {
                class: 'tg-serif-header-4 mb-3.75 text-gray-700'
              },
              letter
            ),
            // Links container
            createElement(
              'div',
              {
                class: 'flex flex-wrap gap-4'
              },
              groupedLinks[letter]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(to => {
                  return createElement(
                    'router-link',
                    {
                      attrs: { to },
                      class:
                        'px-4 py-2 text-sm border rounded-md shadow-md hover:shadow-lg active:shadow-md'
                    },
                    to.name
                  );
                })
            )
          ]);
        })
      ]);
    }
  })
});

window.seti18nLang(process.env.VUE_APP_I18N_LOCALE);

export default VueExtended;
